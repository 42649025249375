import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []; // No specific targets needed as we use unique classes
  connect() {
  }
  resetRows() {
    const hiddenRows = document.querySelectorAll("[class*='rowtogglevisibility-']");
    hiddenRows.forEach(row => {
      row.classList.remove("visually-hidden");
    });
  }

  toggleRowVisibility(event) {
    const index = event.currentTarget.dataset.index;
    const rowsToHide = document.querySelectorAll(`.rowtogglevisibility-${index}`);
    rowsToHide.forEach(row => {
      row.classList.add("visually-hidden");
    });
  }

}
