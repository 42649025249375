import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static values = {
    drugs: Object,
    ingredients: Object
  }
  initialize(){
    this.tooltips = [];
  }

  connect() {
    this.applyColorsToRows();
    this.applyTooltipsToRows();
  }

  applyColorsToRows() {
    // Iterate over each entry in the drugsValue object
    for (const [encryptedDrugCode, colorClass] of Object.entries(this.drugsValue)) {
      let column = document.getElementById("highlight-" + encryptedDrugCode);
      if (column) {
        // Adding 'td-bg-colored' to the current column
        column.classList.add('td-bg-colored');

        // Trim and split the class string to ensure no extra spaces
        const classes = colorClass.trim().split(/\s+/);
        classes.forEach(cls => column.classList.add(cls));

        // Target the previous column (sibling)
        let prevColumn = column.previousElementSibling;
        if (prevColumn) {
          // Apply the same class and gradient to the previous column
          prevColumn.classList.add('td-bg-colored-white-text');
          classes.forEach(cls => prevColumn.classList.add(cls));
        }
      } else {
        console.error("No element found with ID:", "highlight-" + encryptedDrugCode);
      }
    }
  }

  applyTooltipsToRows() {
    for (const [encryptedDrugCode, statement] of Object.entries(this.ingredientsValue)) {
      let column = document.getElementById("highlight-" + encryptedDrugCode);
      if (column) {
        let tooltip = new bootstrap.Tooltip(column, {
          html: true,
          customClass: 'interaction-ex-tooltip',
          container: 'body',
          placement: 'top',
          title: statement
        });
        this.tooltips.push(tooltip);  // Store the tooltip instance for later disposal
      }
    }
  }

  disconnect() {
    this.disposeAllTooltips();  // Dispose of all tooltips when the controller disconnects
  }

  disposeAllTooltips() {
    this.tooltips.forEach(tooltip => tooltip.dispose());
    this.tooltips = [];  // Clear the array after disposing of tooltips
  }
}
