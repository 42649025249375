import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    packageNumber: Number, // Define the package number
    type: String, // "table" or "summary"
  };

  connect() {
    if (this.packageNumberValue === 1) {
      // Store the first table or summary as the standard
      if (this.typeValue === "table") {
        window.standardTableData = this.storeTableData();
      } else if (this.typeValue === "summary") {
        window.standardSummaryData = this.storeSummaryData();
      }
    } else {
      // Compare with the correct standard data
      if (this.typeValue === "table" && window.standardTableData) {
        this.compareTableWithStandard(window.standardTableData);
      } else if (this.typeValue === "summary" && window.standardSummaryData) {
        this.compareSummaryWithStandard(window.standardSummaryData);
      }
    }
  }

  /**
   * Extracts data from table rows.
   */
  storeTableData() {
    const tableData = [];
    this.element.querySelectorAll(":scope > tbody > tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll(":scope > td").forEach((cell) => {
        const value = cell.querySelector("[data-adr-value]")?.getAttribute("data-adr-value");
        rowData.push(value);
      });
      tableData.push(rowData);
    });
    return tableData;
  }

  /**
   * Extracts data from specific indices in summary rows.
   */
  storeSummaryData() {
    const summaryData = [];
    this.element.querySelectorAll(":scope > tbody > tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll(":scope > td").forEach((cell, index) => {
        // Process specific indices: 2, 5, 8, 11, etc.
        if (index > 0 && (index) % 3 === 1) {
          let value = cell.textContent.trim(); // Trim whitespace
          value = value.replace("~", "").replace("%", "").trim(); // Remove '~' and '%'
          rowData.push(value);
        }
      });
      summaryData.push(rowData);
    });
    return summaryData;
  }

  /**
   * Compare table rows with the standard table data.
   */
  compareTableWithStandard(standardData) {
    const rows = this.element.querySelectorAll(":scope > tbody > tr");

    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll(":scope > td");
      const standardRow = standardData[rowIndex];

      if (standardRow) {
        cells.forEach((cell, colIndex) => {
          const comparisonValue = parseFloat(
              cell.querySelector("[data-adr-value]")?.getAttribute("data-adr-value")
          );
          const standardValue = parseFloat(standardRow[colIndex]);
          this.applyComparisonClass(cell, comparisonValue, standardValue);
        });
      }
    });
  }

  /**
   * Compare specific summary indices with the standard summary data.
   */
  compareSummaryWithStandard(standardData) {
    const rows = this.element.querySelectorAll(":scope > tbody > tr");

    rows.forEach((row, rowIndex) => {
      const cells = row.querySelectorAll(":scope > td");
      const standardRow = standardData[rowIndex];

      if (standardRow) {
        let standard_index = -1;
        cells.forEach((cell, index) => {
          if (index > 0 && (index) % 3 === 1) {
            standard_index++;
            let comparisonValue = cell.textContent.trim();
            comparisonValue = parseFloat(comparisonValue.replace("~", "").replace("%", "").trim());
            const standardValue = parseFloat(standardRow[standard_index]); // standard_index
            this.applyComparisonClass(cell, comparisonValue, standardValue);
          }
        });
      }
    });
  }


  applyComparisonClass(cell, comparisonValue, standardValue) {
    if (!isNaN(comparisonValue) && !isNaN(standardValue)) {
      if (comparisonValue > standardValue) {
        cell.classList.add("adr-value-up");
      } else if (comparisonValue < standardValue) {
        cell.classList.add("adr-value-down");
      } else {
        cell.classList.remove("adr-value-up", "adr-value-down");
      }
    }
  }




}