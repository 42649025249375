import { Controller } from "@hotwired/stimulus"
import { driver } from "driver.js";
//don't import default css, it cause issues
// Connects to data-controller="tour"
export default class extends Controller {
  static values = { demo: Boolean }
  connect() {
    if (this.demoValue) {
      if(window.location.pathname === "/" && !localStorage.getItem("tourCompleted")) {
        setTimeout(() => {
          this.virtual_tour_demo();
        }, 2000);
      }
    }

  }
  virtual_tour_demo(){
    const driverObj = driver({
      showProgress: true,
        allowClose: false,
      steps: [
        {
          popover: {
            title: 'Welcome to ADR Control Panel Demo',
            description: 'Please note that this Demo Version serves only the purpose\n' +
                'to illustrate how alerts for drug drug interactions, multiple drug drug interactions and adverse effects are controlled\n' +
                'dependent on the drugs entered. For a free trial with full access to the ADR CP you have to register and sign up.\n',
          }
        },
        {
          element: '.thead-sticky .icons-adr .medicine-name-action',
          popover: {
            title: 'Add and Remove Drugs',
            description: "<p>Please click on the + button to enter your drugs.<br>Please click on the - button to remove all your drugs.</p>",
            side: "bottom",
            align: 'start'
          }
        },
        {
          element: '#drug-interaction-button',
          popover: {
            title: 'Interactions',
            description: 'These are interactive and also display information as traffic lights,<br> You need at least two drugs to view Interactions/Traffic lights. Click on the Traffic lights to view more information. <br> Please Subscribe to access this feature. It is available exclusively to our registered users.  ',
            side: "bottom",
            align: 'start'
          }
        },
        {
          element: '#medicine-packages-button',
          popover: {
            title: 'Optimizer',
            description: 'When more than 2 drugs are entered the Optimizer function can be used. Optimizer is useful in complex polypharmacy scenarios and when treating multimorbide elderly patients. Please Subscribe to access this feature. It is available exclusively to our registered users.',
            side: "top",
            align: 'start'
          }
        },
        {
          element: 'footer a:first-of-type',
          popover: {
            title: 'User Manual',
            description: 'Click on link to read User Manual. it provide details of all functions ADR CP has to offer.',
            side: "top",
            align: 'start'
          }
        },
        {
          element: '#navbarSupportedContent',
          popover: {
            title: 'Menu',
            description: 'It is a drop-down menu. User name initials are visible here. <br> The User can Subscribe by selecting "Sign up and Subscribe" from drop down.',
            side: "right",
            align: 'start'
          }
        },
        {
          popover: {
            title: 'Make your own experience',
            description: 'And that is all, go ahead and start you experience.'
          }
        }
      ]
    });
    driverObj.drive();
    localStorage.setItem("tourCompleted", true);
  }
  virtual_tour_user(){
    const driverObjUser = driver({
      showProgress: true,
      allowClose: false,
      steps: [
        {
          popover: {
            title: 'Welcome to ADR Control Panel',
            description: "    <h5>Summary of the features of the SCHOLZ Databank ADR CP:</h5>\n" +
                "    <ul>\n" +
                "        <li>Highly innovative and dynamic drug drug interaction checker</li>\n" +
                "        <li>Provides the \"all in one\" overview of drug risks in a patient medication</li>\n" +
                "        <li>Reconciles multiple kinetic interactions and adverse drug effects</li>\n" +
                "        <li>e²CP technology visualizes alerts and makes medication errors transparent at one glance</li>\n" +
                "        <li>Multiple e²CP technology: paves the way for the doctor’s fast track to find safe polypharmacy prescriptions for multimorbide patients within seconds</li>\n" +
                "        <li>Interface to EMR- and E-prescribing software</li>\n" +
                "        <li>Based on prescriber information, literature and FDA interaction guidelines</li>\n" +
                "        <li>RXCUI, NDCCODE and Surescripts approved drug database interface for e-prescribing available</li>\n" +
                "    </ul>",
          }
        },
        {
          element: '.thead-sticky .icons-adr .medicine-name-action',
          popover: {
            title: 'Add and Remove Drugs',
            description: "<p>Please click on the + button to enter your drugs.<br>Please click on the - button to remove all your drugs.</p>",
            side: "bottom",
            align: 'start'
          }
        },
        {
          element: '#drug-interaction-button',
          popover: {
            title: 'Interactions',
            description: 'These are interactive and also display information as traffic lights<br> You need at least two drugs to view Interactions/Traffic lights. Click on the Traffic lights to view more information.  ',
            side: "bottom",
            align: 'start'
          }
        },
        {
          element: '#medicine-packages-button',
          popover: {
            title: 'Optimizer',
            description: 'When more than 2 drugs are entered the Optimizer function can be used. Optimizer is useful in complex polypharmacy scenarios and when treating multimorbide elderly patients.',
            side: "top",
            align: 'start'
          }
        },
        {
          element: 'footer a:first-of-type',
          popover: {
            title: 'User Manual',
            description: 'Click on link to read User Manual. it provide details of all functions ADR CP has to offer.',
            side: "top",
            align: 'start'
          }
        },
        {
          element: '#navbarSupportedContent',
          popover: {
            title: 'Menu',
            description: 'It is a drop-down menu. The User can log out using this option. User name initials are visible here.',
            side: "right",
            align: 'start'
          }
        },
        {
          popover: {
            title: 'Make your own experience',
            description: 'And that is all, go ahead and start you experience.'
          }
        }
      ]
    });
    driverObjUser.drive();
  }
}