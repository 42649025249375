import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table"
export default class extends Controller {

  static targets = ["table"]
  static values = {
    condition: Number  // because first two rows contain adr max and names and icons?
  }

  connect() {
    this.updateTableRows(this.tableTarget);
  }

  updateTableRows(table) {
    let table_row = table.rows;
    if (table_row.length > this.conditionValue) {
      for (let i = this.conditionValue; i < table_row.length; i++) {
        if (table_row[i].querySelectorAll("tr .has-popover").length < 1) {
          let table_cells = table_row[i].cells;
          for (let j = table_cells.length - 1; j > 2; j--) {
            table_row[i].deleteCell(j);
          }
          let cell_with_notice = table_row[i].insertCell(3);
          cell_with_notice.innerHTML = "Caution: the adverse effects of this drug are possibly not completely processed, please check the Prescriber Information.";
          cell_with_notice.className = 'cell-with-notice';
          cell_with_notice.colSpan = 34;
        }
      }
    }
  }
}
