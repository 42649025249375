import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="setting"
export default class extends Controller {
  connect() {
    const tablePanel = document.querySelector(".table-panel-adr.button-activation-js");

    if (tablePanel) {
      const tableRows = tablePanel.rows.length;

      // Handle 'drug-interaction-button' if it exists
      const drugInteractionButton = document.getElementById("drug-interaction-button");
      if (drugInteractionButton) {
        drugInteractionButton.disabled = tableRows <= 3;
      }

      // Handle 'medicine-packages-button' if it exists
      const medicinePackagesButtons = document.querySelectorAll(".js-disable-on-condition-2");
      medicinePackagesButtons.forEach(element => {
        element.disabled = tableRows <= 2;
      });
    }
  }
}