import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
    connect() {
        var table_content = this.element.nextElementSibling
        const isInModal = this.element.closest('#modal-adr');
        const container = isInModal ? isInModal : 'body';
        this.my_popover = new bootstrap.Popover(this.element, {
            selector: '.has-popover',
            html: true,
            container: container,
            placement: 'top',
            title: table_content.children[0],
            content: table_content.children[1]
        });
    }

    disconnect(){
        this.my_popover.dispose();
    }
    dismiss() {
    }
    show(){
        this.my_popover.toggle();
    }


}
