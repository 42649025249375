import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["activeMedication", "visibleMedication", "activeMedicationButton", "visibleMedicationButton"];

  connect() {
    this.activeMedicationTarget.classList.remove("visually-hidden");
    this.visibleMedicationTarget.classList.add("visually-hidden");
    this.activeMedicationButtonTarget.classList.add("visually-hidden");
    this.visibleMedicationButtonTarget.classList.remove("visually-hidden");
  }

  toggleActive() {
    this.activeMedicationTarget.classList.toggle("visually-hidden");
    this.visibleMedicationTarget.classList.toggle("visually-hidden");
    this.activeMedicationButtonTarget.classList.toggle("visually-hidden");
    this.visibleMedicationButtonTarget.classList.toggle("visually-hidden");
  }

  toggleVisible() {
    this.toggleActive(); // Since we want both buttons to be mutually exclusive.
  }
}